<template>
  <table style="width: 100%; height: 100%">
    <tr>
      <td style="text-align: center; vertical-align: middle;">
        <div class="login-container">
          <el-form ref="loginForm" class="login-form" auto-complete="on" label-position="left">
            <div class="title-container">
              <h3 class="title" style="height:10x;margin-buttom:30px">
                {{ $t('login.title') }}
              </h3>
              <lang-select v-if="multilingual" class="set-language" />
            </div>
            <el-form-item prop="code">
              <span class="svg-container">
                <svg-icon icon-class="user" />
              </span>
            </el-form-item>
            <div v-if="!isAuthenticated && !autoLogin">
              <el-button style="width:100%;margin-buttom:30px;" @click="userLogin">{{ $t('login.logIn') }}</el-button>
            </div>
            <div v-if="isAuthenticated && !autoLogin">
              <el-button style="width:100%;margin-buttom:30px;" @click="loginAsOtherUser">{{
                $t('login.logInWithAnotherAccount')
              }}</el-button>
            </div>
          </el-form>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
import { hasAuthParams, getToken } from '@/utils/auth';
import SvgIcon from '@/components/SvgIcon';
import LangSelect from '@/components/LangSelect';

export default {
  name: 'Login',
  components: {
    LangSelect,
    SvgIcon
  },
  data() {
    return {
      isAuthenticated: false,
      redirect: null,
      autoLogin: false
    };
  },
  computed: {
    multilingual() {
      return this.$store.getters['multilingual'];
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
        this.autoLogin = route.query && route.query.autologin;
      },
      immediate: true
    }
  },
  async created() {
    if (hasAuthParams()) {
      await this.handleRedirectCallback();
    } else {
      await this.checkIfAuthenticated();
    }
  },
  methods: {
    async checkIfAuthenticated() {
      if (this.hasToken()) {
        const isExpired = this.$store.getters['isTokenExpired'];
        if (isExpired) {
          await this.handleExpiredSession();
        } else {
          this.isAuthenticated = true;
        }
      } else {
        if (this.autoLogin) {
          this.autoLogin = false;
          await this.handleExpiredSession();
        } else {
          this.isAuthenticated = false;
        }
      }
    },
    hasToken() {
      const token = getToken();
      return !!token;
    },
    async handleRedirectCallback() {
      try {
        const user = await this.$oidc.signinRedirectCallback();
        if (user && user.id_token) {
          await this.setToken(user.id_token);
          this.redirectUser();
        }
      } catch (error) {
        console.log(error);
        this.isAuthenticated = false;
      }
    },
    async handleExpiredSession() {
      try {
        const user = await this.$oidc.signinSilent();
        await this.setToken(user.id_token);
        this.redirectUser();
      } catch (error) {
        console.log(error);
        this.isAuthenticated = false;
      }
    },
    async setToken(token) {
      this.isAuthenticated = true;
      await this.$store.dispatch('setToken', token);
    },
    redirectUser() {
      this.$router.push({ path: this.redirect || '/' });
    },
    getUser() {
      return this.$oidc.getUser();
    },
    async userLogin() {
      await this.$oidc.signinRedirect();
    },
    async loginAsOtherUser() {
      await this.$store.dispatch('frontEndLogout');
      await this.$oidc.signoutRedirect();
    }
  }
};
</script>

<style src="@/styles/components/login/input.scss" lang="scss"></style>

<style src="@/styles/components/login/index.scss" lang="scss" scoped></style>
